import * as React from "react";
import { Link } from "gatsby";

import styled, { createGlobalStyle } from "styled-components";

import "./normalize.css";
import "@fontsource/open-sans";

const BODY_COLOR = "#444";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: "Open Sans";
    font-size: 14px;
    background-color: #f8f8f8;
    color: ${BODY_COLOR};
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 768px;
  padding: 0 8px;
`;

const Subtitle = styled.p`
  color: #777;
`;

const Header = styled.div`
  text-align: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 24px;

  a {
    color: ${BODY_COLOR};
    text-decoration: none;
  }
`;

const Footer = styled.div`
  color: #777;

  text-align: center;
  border-top: 1px solid #ccc;
  margin-top: 24px;

  a:link,
  a:visited {
    color: #777;
  }
`;

const FooterParagraph = styled.p`
  font-size: 10px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <h1>
            <Link to="/">PimpMyPass</Link>
          </h1>
          <Subtitle>
            Parce que ça serait trop con de ne plus avoir de batterie : porte
            ton pass.
          </Subtitle>
        </Header>
        <div>{children}</div>
        <Footer>
          <FooterParagraph>
            Créé avec ❤️ depuis la France pour que tu ne sois jamais bloqué à
            l'extérieur d'un bar, d'un resto, d'un ciné ou d'un poney-club. Si
            tu as vraiment envie de les lire :{" "}
            <Link to="/legal">les mentions légales</Link>.
          </FooterParagraph>
          <FooterParagraph>
            © PimpMyPass 2021 et jusqu'à la fin de <b>la</b> COVID.
          </FooterParagraph>
        </Footer>
      </Container>
    </>
  );
};
